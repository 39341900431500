import React from 'react';
import styled from 'styled-components';
import { Color, Width, xxlMobile, xxlDesktop, bottomMargin, topMargin, linkDefault, typography, padding, displayNone } from '../../../styles';
import { H2, JumpToDiv, JumpToLink, Divider } from './SharedComponents';
import {ArrowDown} from "../../Svg";
import { StaticImage } from 'gatsby-plugin-image';

import {
  Blurb,
  FounderDesc,
  FounderDescHeader,
  ImageContainer,
  Wrapper as BaseWrapper,
} from '../products/BaseHeroComponentStyle';


const Wrapper = styled.section`
  background: ${Color.DARK_GRAY};
  padding-block-start: 136px;

  @media screen and (min-width: ${Width.BREAK_MD}px) {
    padding-block-start: 152px;
  }

  @media screen and (min-width: ${Width.BREAK_XL}px) {
    padding-block-start: 206px;
  }
`;

const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(auto, 98.7rem) 1fr;

  & > * {
    grid-column: 2;
  }

  ${padding('0px 24px 80px', '0px 40px 104px', '0px 0px 120px')};
`;

/* Heading needs to stay aligned to the logo */
const H1 = styled.h1`
  color: ${Color.SEAFOAM_GREEN};
  margin-block-end: 40px;

  margin-inline: auto;
  width: calc(100% - 48px);
  max-width: 1200px;

  /* Mobile font styles are slightly different from styles defined in the typography folder */
  ${typography('6.3rem', '.84', '.016em')};

  @media screen and (min-width: ${Width.BREAK_MD}px) {
    ${xxlMobile};
    margin-block-end: 64px;
    width: calc(100% - 80px);
  }

  @media screen and (min-width: ${Width.BREAK_XL}px) {
    ${xxlDesktop};
    margin-block-end: 48px;
  }
`;

const StyledWrapper = styled(BaseWrapper)`
  ${bottomMargin(5, 10, 15)};
  ${topMargin(56, 105, 88)};

  a {
    ${linkDefault};
  }

  .mobile {
    ${displayNone('block', false, true, true)};
  }
  .tablet {
    ${displayNone('block', true, false, true)};
  }
  .desktop {
    ${displayNone('block', true, true, false)};
  }
`;

const TeamSection = () => {
    
  return (
  <Wrapper id="team">
    <H1>Leadership</H1>
    <SectionGrid>
      <JumpToDiv>
        <H2 $inputColor={Color.OFF_WHITE}>Our Founder and CEO</H2>
        <JumpToLink href="#advisors" $inputColor={Color.OFF_WHITE}>
          Jump to Advisors
          {ArrowDown}
        </JumpToLink>
      </JumpToDiv>
      <Divider />
      <StyledWrapper>
    <ImageContainer>
      <StaticImage className={'mobile'} src={'../../../images/leadership/team/ashley-bell.png'} style={{ width: '65%', margin: 'auto 25%' }} alt={''} placeholder={'none'} />
      <StaticImage className={'tablet'} src={'../../../images/leadership/team/ashley-bell.png'} style={{ width: '75%', margin: 'auto'}} alt={''} placeholder={'none'} />
      <StaticImage className={'desktop'} src={'../../../images/leadership/team/ashley-bell.png'} style={{ width: '85%', margin: 'auto 25%'}} alt={''} placeholder={'none'} />
    </ImageContainer>
    <Blurb>
      <FounderDescHeader >Ashley D. Bell</FounderDescHeader>

      <FounderDesc>Ashley D. Bell is an accomplished attorney, banking professional and serial entrepreneur.</FounderDesc>

      <FounderDesc>
      Ashley is a Georgia-based attorney and political adviser who founded and currently serves as CEO of Ready Life, a fintech revolutionizing the home buying experience for Black Americans by removing the use of credit scores. He also helms the Black-led investor group preparing to acquire the Utah-based, nonminority-owned Holladay Bank & Trust, a first-of-its-kind transaction that will create Redemption Bank, pending regulatory approval.
      </FounderDesc>

      <FounderDesc>
      Ashley’s legal expertise spans public policy, business and banking, and he is the former White House Policy Advisor for Entrepreneurship & Innovation in the office of American Innovation. In 2018,Ashley was tapped to lead the U.S. Small Business Administration’s Southeast Region. He also co-founded the National Black Bank Foundation alongside frequent collaborator Bernice King, daughter of civil rights icon Dr. Martin Luther King Jr.
      </FounderDesc>
    </Blurb>
  </StyledWrapper>
    </SectionGrid>
  </Wrapper>
        )
  }

export default TeamSection;
